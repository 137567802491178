<template>
  <div>
    <dialog-lista-cidades ref="dialogCidades"></dialog-lista-cidades>
    <v-container>
      <v-subheader class="text-subtitle-1 text--primary">
        Cidades/Estados
      </v-subheader>
      <v-card>    
        <v-toolbar flat color="white">
          <v-text-field filled flat single-line hide-details dense clearable rounded v-model="busca" max-width="300px" prepend-inner-icon="search" label="Buscar Estado"></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table :options.sync="options" :footer-props="footerOptions" :headers="headers" :items="estados" :search="busca" :loading="carregando">
          <template v-slot:item="{item}">
            <tr>
              <td>{{ item.nome }}</td>
              <td>{{ item.uf }}</td>
              <td>{{ item.codigo }}</td>
              <td class="text-center">
                <v-icon @click="exibirCidades(item)">mdi-details</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>

  import {mapState, mapGetters, mapActions} from 'vuex';
  import constantes from '@/util/constantes';
  import DialogListaCidades from './DialogListaCidades';

  export default {
    name: "ListaCidadesEstados",
    components: {
      DialogListaCidades
    },

    data () {
      return {
        headers: [
          { text: 'Nome', value: 'nome' },
          { text: 'UF', value: 'uf' },
          { text: 'Código', value: 'codigo' },
          { text: 'Ações', value: 'name', align:'center',  sortable: false }
        ],
        constantes: constantes,
        busca: '',
        options: {
          itemsPerPage: 10,
          page: 1
        },
        footerOptions: {
          'items-per-page-options': constantes.ITEMS_POR_PAGINA 
        },
        estadoSelecionado: null
      }
    },

    watch: {
      options: {
        handler () {
          this.carregarEstados();
        },
        deep: true,
      },
    },

    computed: {
      ...mapState('cidadeEstado', {
        estados: 'estados',
        carregando: 'carregando'
      })
    },

    methods: {
      carregarEstados(){
        this.$store.dispatch('cidadeEstado/carregarEstados');
      },

      async exibirCidades(estado){
        try{
          await this.$store.dispatch('cidadeEstado/exibirCidades', estado);
          this.$refs.dialogCidades.abrir(estado);
        }
        catch(erro){
          console.log('erro ao listar cidades', erro);
        }
      }
    },
  }
</script>