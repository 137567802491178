<template>
  <v-dialog v-model="dialog" max-width="800px" @keydown.esc="fechar" persistent scrollable>
    <v-card>
      <v-toolbar dark flat color="primary">
        <h3>Cidades do Estado - {{estado.uf}} - {{estado.nome}}</h3>
        <v-spacer></v-spacer>
        <v-icon style="cursor: pointer" @click="fechar()">close</v-icon>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-text-field flat solo single-line hide-details clearable v-model="busca" max-width="300px" prepend-inner-icon="search" label="Buscar Cidade"></v-text-field>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="height: 400px">
        <v-data-table :headers="headers" :items="cidades" :search="busca" :loading="carregando">
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.cidade }}</td>
              <td>{{ props.item.codigo_cidade }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  
  import {mapState, mapActions} from 'vuex';

  export default {
    
    data: () => ({
      valid: false,
      dialog: false,
      estado: '',
      busca: '',
      headers: [
          { text: 'Nome da Cidade', value: 'cidade' },
          { text: 'Código da Cidade', value: 'codigo_cidade' },
        ],
    }),

    computed: {

      ...mapState('cidadeEstado', {
        cidades: 'cidades',
        carregando: 'carregando'
      })
    },

    methods: {

      abrir(estado) {
        this.estado = estado;
        this.dialog = true;

        setTimeout(() => {
          this.$refs.input.focus();
        }, 0);
      },

      fechar(){
        this.dialog = false;
        this.$emit('fechar');
      },
    }
  }
</script>